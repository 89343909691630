import { setId } from '@/scripts/helper';

const getStyle = () => {
  return {
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    font_color: {
      rgb: [],
      cmyk: []
    }
  };
};

export const getDefaultText = () => {
  return {
    node_id: setId(),
    node_type: 'text',
    content: {
      text: '',
      align: 'left'
    },
    margin: {
      before: '0mm'
    },
    style: getStyle()
  };
};

export const getDefaultImg = () => {
  return {
    node_id: setId(),
    node_type: 'image',
    content: {
      url: '',
      alias_url: ''
    },
    position: {
      x: '0mm',
      y: '0mm'
    },
    img_size: {
      width: '0mm',
      height: '0mm'
    }
  };
};

export const getDefaultPagenum = () => {
  return {
    node_id: setId(),
    node_type: 'page_number',
    content: {
      text: '@PN'
    },
    position: {
      x: '0mm',
      y: '0mm'
    },
    style: getStyle()
  };
};

export const defaultFormat = function (cfg) {
  if (!cfg) return [];
  return [
    {
      name: '空白（静态文字）',
      type: 'text',
      img: require('./img/空白（静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（动静态文字）',
      type: 'text',
      img: require('./img/空白（动静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
        ]
      }
    },
  ];
};
