<template>
  <el-card class="book-header">
    <div class="header">
      <input ref="file" type="file" class="file-input" :accept="actImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="fileChange($event, imgComponents[0])">
      <tabs v-model="tabIndex" :tabs="tabs" class="inline-tab" />
      <div class="check-box">
        <el-checkbox-group :value="checkList" @input="changeCheckList">
          <el-checkbox label="水印分左右页显示"></el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="check-box">
      <el-checkbox-group :value="component.fbd.enable_fbd" @input="changeEnabledFbd">
        <el-checkbox label="是否使用方正代码进行配置"></el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="body">
      <div v-if="!component.fbd.enable_fbd" class="preview" ref="preview">
        <div class="page" :style="pageStyle">
          <div class="margin-top" :style="{ height: marginStyle.top }"></div>
          <div class="margin-center">
            <div class="margin-left" :style="{ width: marginStyle.left }">
            </div>
            <div class="page-core">
              <div class="watermark" :style="watermarkStyle" v-if="imgComponents.length && imgComponents[0].content.url"
                @dblclick="uploadImage">
                <img :src="imgComponents[0].content.url + '?x-oss-process=image/format,jpg'" alt=""
                  style="width: 100%;height: 100%" />
              </div>
              <div v-if="imgComponents.length && !imgComponents[0].content.url" class="empty" @dblclick="uploadImage">
                <span>
                  双击此处添加图片
                </span>
              </div>
            </div>
            <div class="margin-right" :style="{ width: marginStyle.right }">
            </div>
          </div>
          <div class="margin-bottom" :style="{ height: marginStyle.bottom }">
          </div>
        </div>
      </div>
      <div v-if="!component.fbd.enable_fbd" class="operate">
        <div class="config">
          <el-timeline>
            <el-timeline-item hide-timestamp :icon="actDot.includes('0') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('0') ? 'primary' : 'info'" size="large">
              <span class="timeline-title" @click="handleDot('0')">水印配图</span>
              <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addImg()">新增</el-button>
              <el-row v-if="actDot.includes('0')" class="config-item">
                <div class="img-item" v-for="(item, index) in imgComponents" :key="item.node_id">
                  <div class="header">
                    <div>水印配图{{String.fromCharCode(index + 9312)}}</div>
                    <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
                  </div>
                  <div class="img-body">
                    <el-form :inline="true" size="mini" label-position="right" label-width="80px">
                      <el-form-item label="配图上传：">
                        <el-row class="m-b-10">
                          <span style="display: inline-block; width: 60px">配图
                            <el-tooltip class="item" effect="light" content="提示：配图不支持tif, eps格式，需要该格式的图片可以上传到备用图" placement="top" :open-delay="300">
                              <i class="el-icon-info"></i>
                            </el-tooltip>
                          </span>
                          <el-badge :is-dot="!!item.content.url">
                            <el-button class="style-tool-button" @click="uploadImage">上传</el-button>
                          </el-badge>
                        </el-row>
                        <el-row>
                          <span style="display: inline-block; width: 60px">备用图 </span>
                          <el-badge :is-dot="!!item.content.alias_url">
                            <el-button class="style-tool-button" @click="uploadAliasImage">上传</el-button>
                          </el-badge>
                        </el-row>
                    </el-form-item>
                      <el-form-item label="图片坐标：">
                        <span class="label">X </span>
                        <unit-input v-model="item.position.x" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                        <br>
                        <span class="label">Y </span>
                        <unit-input v-model="item.position.y" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                      </el-form-item>
                      <el-form-item label="图片尺寸：">
                        <el-row class="connection-box">
                          <div class="connection-style">
                            <svg-icon name="chain" class="icon-connection"></svg-icon>
                          </div>
                          <div class="input-box">
                            <span class="label m-b-10">高 </span>
                            <unit-input v-model="item.img_size.height" unit="mm" step="1" min="0" @input="handleHeight($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                          <div class="input-box">
                            <span class="label">宽 </span>
                            <unit-input v-model="item.img_size.width" unit="mm" step="1" min="0" @input="handleWidth($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                        </el-row>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-row>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <FbdEditor
        v-if="component.fbd.enable_fbd"
        v-model="component.fbd.code_template"
        style="width: 100%"
        :options="{ height: 150 }"
      />
    </div>
  </el-card>
</template>

<script>
import UnitInput from '../components/UnitInput';
import { getDPI } from '@/scripts/helper';
import { defaultFormat, getDefaultImg, getDefaultPagenum } from './assets/format.js';
import _ from 'lodash';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    UnitInput,
    FbdEditor,
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  created () {
    this.init();
  },
  mounted () {
    this.computePreview();
    window.addEventListener('resize', this.computePreview);
  },
  destroyed () {
    window.removeEventListener('resize', this.computePreview);
  },
  data () {
    return {
      dpi: getDPI(),
      fileVal: '',
      files: null,
      actImg: 0,
      data: null,
      checkList: [],
      actDot: ['0'],
      defaultFormat: defaultFormat(this.styleData),
      tabIndex: 0,
      margin_type: 'watermark',
      transformScale: 1,
    };
  },
  computed: {
    watermarkStyle () {
      const [img] = this.imgComponents;
      if (!img) return {};
      const { img_size: { width, height }, position: { x, y } } = img;
      return {
        width: parseFloat(width) * this.transformScale + 'px',
        height: parseFloat(height) * this.transformScale + 'px',
        left: parseFloat(x) * this.transformScale + 'px',
        top: parseFloat(y) * this.transformScale + 'px',
        position: 'absolute',
      };
    },
    marginStyle () {
      if (!this.transformScale) return {};
      const { page_margin: { top, right, bottom, left } } = this.styleData.components[0].page_cfg;
      return {
        top: parseFloat(top) * this.transformScale + 'px',
        right: parseFloat(right) * this.transformScale + 'px',
        bottom: parseFloat(bottom) * this.transformScale + 'px',
        left: parseFloat(left) * this.transformScale + 'px'
      };
    },
    pageStyle () {
      let { components: [{ page_cfg: { page_size: { width, height } } }] } = this.styleData;
      width = parseFloat(width);
      height = parseFloat(height);
      return {
        width: width * this.transformScale + 'px',
        height: height * this.transformScale + 'px'
      };
    },
    imgComponents () {
      const { data } = this.component;
      return data.filter(item => item.node_type === 'image');
    },
    component () {
      const { data: { margin_components }, margin_type } = this;
      const { page_selector } = this.tabs[this.tabIndex];
      const data = margin_components.find(item => item.margin_type === margin_type && item.page_selector === page_selector);
      data.fbd = data.fbd || {};
      data.fbd.enable_fbd = data.fbd.enable_fbd || false;
      data.fbd.code_template = data.fbd.code_template || '';
      console.log('[component]', data);
      return data;
    },
    tabs () {
      const tabs = [];
      const { checkList } = this;
      if (checkList.includes('水印分左右页显示')) {
        tabs.push({ name: '左页水印', index: tabs.length, page_selector: 'odd' }, { name: '右页水印', index: tabs.length + 1, page_selector: 'even' });
      } else {
        tabs.push({ name: '左右页水印', index: tabs.length, page_selector: 'page' });
      }
      return tabs;
    }
  },
  methods: {
    handleWidth (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.height = Math.round((parseFloat(val) / (img.width / this.dpi.y * 25.4) * (img.height / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.width = val;
        };
      }
    },
    handleHeight (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.width = Math.round((parseFloat(val) / (img.height / this.dpi.y * 25.4) * (img.width / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.height = val;
        };
      }
    },
    uploadImage () {
      this.actImg = 0;
      this.$nextTick(function () {
        this.$refs.file.click();
      });
    },
    uploadAliasImage () {
      this.actImg = 1;
      this.$nextTick(function () {
        this.$refs.file.click();
      });
    },
    computePreview () {
      if (!this.$refs.preview) {
        return;
      }
      const { offsetWidth, offsetHeight } = this.$refs.preview;
      let { page_size: { width, height } } = this.styleData.components[0].page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      const scale = width / height;
      if (scale > offsetWidth / offsetHeight) {
        this.transformScale = offsetWidth * 0.9 / width;
      } else {
        this.transformScale = offsetHeight * 0.9 / height;
      }
    },
    setUrl (url, item) {
      if (this.actImg === 1) {
        item.content.alias_url = url;
        return;
      }
      const img = new Image();
      img.src = url + '?x-oss-process=image/format,jpg';
      item.content.url = url;
      img.onload = () => {
        let { components: [{ page_cfg: { page_core_size: { width: maxWidth, height: maxHeight } } }] } = this.styleData;
        maxHeight = parseFloat(maxHeight);
        maxWidth = parseFloat(maxWidth);
        item.natural_size = { width: img.width, height: img.height };
        const width = Math.round((img.width / this.dpi.x * 25.4) * 10) / 10;
        const height = Math.round((img.height / this.dpi.y * 25.4) * 10) / 10;
        const scale = width / height;
        if (maxWidth / maxHeight > scale) {
          if (height > maxHeight) {
            item.img_size = {
              width: maxHeight * scale + 'mm',
              height: maxHeight + 'mm'
            };
            return;
          }
        } else {
          if (width > maxWidth) {
            item.img_size = {
              width: maxWidth + 'mm',
              height: maxWidth / scale + 'mm'
            };
            return;
          }
        }
        item.img_size = {
          width: width + 'mm',
          height: height + 'mm'
        };
      };
    },
    fileChange (evt, item) {
      const file = evt.target.files[0];
      this.files = file;
      this.fileUploadOss(item);
    },
    transformColor (val, item) {
      let rgb = val && val.match(/(?<=\()(\d+), *(\d+), *(\d+)(?=\))/)[0] || '';
      if (rgb) {
        rgb = rgb.split(',').map(item => +item);
      } else {
        rgb = [];
      }
      item.style.font_color.rgb = rgb;
      if (rgb.length) {
        item.style.font_color.cmyk = this.rgb2cmyk(rgb);
      } else {
        item.style.font_color.cmyk = [];
      }
    },
    inputCmyk (val, position, item) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { font_color, font_color: { cmyk } } = item.style;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      font_color.rgb = this.cmykToRGB(cmyk);
    },
    rgb2cmyk ([r, g, b]) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));
      c = Math.round((c - k) / (1 - k) * 100);
      m = Math.round((m - k) / (1 - k) * 100);
      y = Math.round((y - k) / (1 - k) * 100);
      k = Math.round(k * 100);
      c = isNaN(c) ? 0 : c;
      m = isNaN(m) ? 0 : m;
      y = isNaN(y) ? 0 : y;
      k = isNaN(k) ? 0 : k;
      return [c, m, y, k];
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    getColor (rgb) {
      if (!rgb || !rgb.length) {
        return 'rgb(0, 0, 0)';
      }
      return `rgb(${rgb.join()})`;
    },
    init () {
      this.data = this.styleData.components[0];
      let { margin_components } = this.data;
      if (!margin_components) margin_components = this.$set(this.data, 'margin_components', []);
      const { margin_type } = this;
      if (!margin_components.length || !margin_components.find(item => item.margin_type === margin_type)) {
        margin_components.push(this.defaultMarginComponent());
      }
      const marginComponent = margin_components.find(item => item.margin_type === margin_type);
      const { page_selector } = marginComponent;
      if (page_selector !== 'page') {
        this.checkList.push('水印分左右页显示');
      }
    },
    addImg () {
      const { component: { data }, imgComponents } = this;
      if (imgComponents.length > 0) {
        this.$message.info('目前暂时只支持一个配图');
        return;
      }
      data.push(getDefaultImg());
      if (!this.actDot.includes('0')) this.handleDot('0');
    },
    addPagenum () {
      const { component: { left: { data } }, pagenumComponents } = this;
      if (pagenumComponents.length > 0) {
        this.$message.info('目前暂时只支持一个页码');
        return;
      }
      data.push(getDefaultPagenum());
    },
    delItem ({ node_id }) {
      const { component: { data } } = this;
      const index = data.findIndex(item => item.node_id === node_id);
      index >= 0 && data.splice(index, 1);
    },
    checkPagenum (pagenum) {
      if (pagenum === '@PN' || pagenum === '-@PN-' || pagenum === '·@PN·') return pagenum;
      return '自定义';
    },
    changePagenum (pagenum, content) {
      if (pagenum === '自定义') return;
      content.text = pagenum;
    },
    checkText (text) {
      return /^@CPLV[1-9]$/.test(text) ? 'title' : 'text';
    },
    changeTextType (val, content) {
      if (val === 'text') {
        content.text = '';
      } else {
        content.text = '@CPLV1';
      }
    },
    defaultMarginComponent (page_selector = 'page') {
      const { margin_type } = this;
      return {
        margin_type,
        page_selector,
        relative_margin: '0mm',
        margin_before: '0mm',
        offset: '0mm',
        data: [],
        fbd: { enable_fbd: false, code_template: '' },
      };
    },
    handleDot (item) {
      const index = this.actDot.indexOf(item);
      if (index >= 0) {
        this.actDot.splice(index, 1);
      } else {
        this.actDot.push(item);
      }
    },
    changeCheckList (val) {
      let { margin_components } = this.data;
      margin_components = margin_components.filter(item => !this.margin_type.includes(item.margin_type));
      if (val.includes('水印分左右页显示')) {
        const pageMargin = this.data.margin_components.find(item => item.margin_type === this.margin_type && item.page_selector === 'page');
        if (pageMargin) {
          margin_components.push(Object.assign(_.cloneDeep(pageMargin), { page_selector: 'odd' })
            , Object.assign(_.cloneDeep(pageMargin), { page_selector: 'even' }));
        } else {
          margin_components.push(this.defaultMarginComponent('odd'), this.defaultMarginComponent('even'));
        }
      } else {
        margin_components.push(this.defaultMarginComponent());
      }
      this.data.margin_components = margin_components;
      this.tabIndex = this.tabs[0]?.index;
      this.checkList = val;
    },
    changeEnabledFbd (enabledFbd) {
      this.component.fbd.enable_fbd = enabledFbd;
      // console.log(this.component.fbd);
    },
  }
};
</script>

<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.book-header {
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    flex: none;
    display: flex;
    align-items: center;
    .inline-tab {
      /deep/ .tabs-item {
        min-width: 100px;
      }
      /deep/ .slide {
        transition: transform .2s;
      }
    }
    .check-box {
      margin-left: 10px;
      /deep/ .el-checkbox {
        margin: 10px;
      }
      /deep/ .el-checkbox__label {
        width: unset;
      }
    }
  }
  .body {
    flex: auto;
    display: flex;
    margin-top: 4px;
    height: calc(100% - 46px);
    .preview {
      flex: none;
      width: 50%;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 4px black inset;
      padding: 10px;
      .page {
        background-color: #fff;
        border: 1px solid #ccc;
        display: flex;
        position: relative;
        flex-direction: column;
        font-size: 12px;
        color: #888;
        flex: none;
        .margin-top, .margin-bottom {
          flex: none;
        }
        .margin-center {
          flex: auto;
          display: flex;
          .margin-right, .margin-left {
            flex: none;
          }
          .page-core {
            flex: auto;
            border: 1px dashed #ccc;
            position: relative;
            .watermark {
              cursor: pointer;
            }
            .empty {
              position: absolute;
              left: 50%;
              top: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              width: 50%;
              height: 50%;
              background: url('./assets/empty.png') no-repeat 0 0;
              background-size: 100% 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .operate {
      flex: auto;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      .config {
        flex: auto;
        overflow-y: auto;
        padding: 20px;
        .upload-btn {
          padding: 0;
          font-size: 12px;
          height: 17px;
          line-height: 15px;
        }
        .label {
          font-size: 12px;
          color: #606266;
        }
        .connection-box {
          width: 110px;
          position: relative;
          .connection-style {
            position: absolute;
            right: -20px;
            width: 20px;
            top: 14px;
            bottom: 14px;
            border: 1px solid #ccc;
            border-left-color: transparent;
            .icon-connection {
              position: absolute;
              right: -30px;
              top: 60%;
              transform: translate(0, -50%);
            }
          }
        }
        .timeline-title {
          font-weight: 500;
          cursor: pointer;
        }
        /deep/ .el-radio {
          margin-right: 10px;
          font-size: 12px;
        }
        /deep/ .el-radio__label {
          width: unset;
          font-size: 12px;
        }
        /deep/ .el-form-item__label {
          font-size: 12px;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
        }
        .config-item {
          padding-top: 20px;
          padding-left: 10px;
          .text-item, .img-item, .pagenum-item {
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 4px;
              font-size: 13px;
              .style-tool-button--text {
                color: #A2A2A2;
                font-size: 12px;
                &:hover {
                  color: $danger-color;
                }
              }
            }
            .text-body, .img-body, .pagenum-body {
              background-color: #F2F6FC;
              padding: 20px;
            }
          }
          .text-item + .text-item {
            margin-top: 10px;
          }
        }
      }
    }

  }
}
</style>
